import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './footer.css'
const Layout = ({ children }) => {
  return (
    <div className="d-flex flex-column" style={{ height: '100vh' }}>
      <main className="main-content flex-grow-1">{children}</main>
      <footer className="custom-footer-color text-white d-flex align-items-center justify-content-center py-3">
        <p className="mb-0">Created by Pursuit Limited</p>
      </footer>
    </div>
  )
}

export default Layout
