import React, { useState, useEffect } from 'react'
import './Home.css'
import { useNavigate } from 'react-router-dom'
import image1 from '../../assets/images/slideshow/maraeImg1.jpg'
import image2 from '../../assets/images/slideshow/maraeImg2.jpg'
import image3 from '../../assets/images/slideshow/maraeImg3.jpg'
import image4 from '../../assets/images/slideshow/maraeImg4.jpg'
import image5 from '../../assets/images/slideshow/maraeImg5.jpg'
import image6 from '../../assets/images/slideshow/maraeImg6.jpg'
import image7 from '../../assets/images/slideshow/maraeImg7.jpg'
import image8 from '../../assets/images/slideshow/maraeImg8.jpg'
import image9 from '../../assets/images/slideshow/maraeImg9.jpg'
import image10 from '../../assets/images/slideshow/maraeImg10.jpg'
import image11 from '../../assets/images/slideshow/maraeImg11.jpg'
import image12 from '../../assets/images/slideshow/maraeImg12.jpg'
import image13 from '../../assets/images/slideshow/maraeImg13.jpg'

const Home = () => {
  const navigate = useNavigate()
  const handleButtonClick = () => navigate('/book')

  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="container-fluid mt-5">
      <div className="row m-0">
        <div className="col-md-6 order-md-1 px-0 d-flex flex-column justify-content-center align-items-center">
          <h1 className="custom-text">
            Te Rauhina te wahine kōrero āio a Tapuwae!
          </h1>
          <div className="button-container mt-3">
            <button
              className="btn btn-primary custom-button"
              aria-label="Book now"
              onClick={handleButtonClick}>
              Book Now
            </button>
          </div>
        </div>
        <div className="col-md-6 bg-color order-md-2 p-0">
          <div className="image-container">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className={`img-fluid custom-image ${
                  index === currentImageIndex ? 'visible' : ''
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
