import React, { useState, useEffect } from 'react'
import './About.css'
import { useNavigate } from 'react-router-dom' // Step 1: Import useNavigate instead of useHistory
import image1 from '../../assets/images/1.JPG'
import image2 from '../../assets/images/2.JPG'
import image3 from '../../assets/images/3.JPG'
import image4 from '../../assets/images/4.JPG'

const About = () => {
  const navigate = useNavigate() // Step 2: Use the useNavigate hook

  const handleButtonClick = () => navigate('/book')

  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const images = [image1]

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="container-fluid mt-5">
      <div className="row m-0">
        <div className="col-md-6 order-md-1 p-0">
          <div className="image-container">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className={`img-fluid custom-image ${
                  index === currentImageIndex ? 'visible' : ''
                }`}
              />
            ))}
          </div>
        </div>
        <div className="col-md-6 order-md-2 d-flex flex-column justify-content-center align-items-center">
          <h1 className="custom-text">Ko wai mātau</h1>

          <div className="button-container mt-3">
            <h1 className="custom-text2">
              Ko Huianui te maunga
              <br />
              Ko Te Wairoa hōpūpū hōnengenenge
              <br />
              matangirau te awa
              <br />
              Ko Tākitimu te waka
              <br />
              Ko Ngāti Kahungunu te iwi
              <br />
              Ko Te Rauhina te marae
              <br />
              Ko Te Āio te wharekai
              <br />
              Ko Te Uri Okākā te urupā
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
