import React from 'react'
import './Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

function Contact() {
  return (
    <div className="container mt-5 pt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <h1 className="custom-text text-center mb-5">Find Us Here</h1>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                title="map"
                className="mx-auto d-block w-100"
                height="500"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3190.7071870000003!2d177.4353602!3d-39.0526826!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d689ba6c80b0853%3A0x9e343ed8f85eca3a!2sTe%20Rauhina%20Marae!5e0!3m2!1sen!2s!4v<YOUR_TIMESTAMP_HERE>"></iframe>
            </div>
          </div>
          <p className="text-center mt-3 fw-bold">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
            Te Rauhina Marae, Ngāmotu Rd, Wairoa 4108
          </p>
          <p className="text-center mt-3 fw-bold">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            terauhinamarae.trustees@gmail.com
          </p>
        </div>
      </div>
    </div>
  )
}

export default Contact
