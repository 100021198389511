import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './assets/styles/index.css'

import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from './components/Navbar/Navbar'
import Layout from './components/Layout/Layout'
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Book from './pages/Book/Book'
import Contact from './pages/Contact/Contact'

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/book" element={<Book />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Layout>
      </div>
    </Router>
  )
}

export default App
